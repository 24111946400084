import { ErrorBoundary, Show, Suspense, createResource } from "solid-js";
import { getArticle } from "~/lib/fetcher";
import CardShell from "~/components/Cards/CardShell";
import type { ItemReference } from "~/types/drupal_jsonapi";
import imgProxy from "~/utils/imgproxy";
import { useEventsContext } from "~/contexts/EventsContext";

import "./ArticleCard.css";

import IconSchedule from "~/img/icons/schedule.svg";
import IconKeyboardArrowRight from "~/img/icons/keyboard_arrow_right.svg";
import { urlRs } from "~/utils/url";
import { formatDateUStoFrenchShortString } from "~/utils/format";

export default function ArticleCard(props: {
  nid: number;
  initialDeferStream?: boolean;
  item: ItemReference;
  isPromoted?: boolean;
}) {
  const [article] = createResource(() => props.nid + "", getArticle, {
    deferStream: props.initialDeferStream,
  });

  const [, { sendClick }] = useEventsContext();

  return (
    <>
      <ErrorBoundary
        fallback={(err, reset) => (
          <p style="border: 1px solid red" onClick={reset}>
            Error: {err.toString()}
          </p>
        )}
      >
        <Suspense
          fallback={<CardShell title={props.item.title} url={props.item.url} />}
        >
          <Show when={article()}>
            <article
              class="node-article-card"
              classList={{ promoted: props.isPromoted }}
              data-test={
                props.isPromoted ? "card-article-promoted" : "card-article"
              }
            >
              <Show when={article()!.field_image}>
                <picture class="visual">
                  <img
                    fetchpriority="high"
                    src={imgProxy(
                      article()!.field_image.uri.url,
                      `size:700:300/resizing_type:fill`,
                    )}
                    alt={article()!.field_image.meta?.alt}
                    height="160"
                    width="375"
                    loading="lazy"
                  />
                </picture>
              </Show>
              <div class="content-article">
                <span class="submitted" data-test="reading">
                  <i aria-hidden="true" class="cog-icon">
                    <IconSchedule />
                  </i>{" "}
                  {article()!.field_reading_time} &nbsp;-&nbsp;{" "}
                  {formatDateUStoFrenchShortString(article()!.created)}
                </span>
                <h3>{article()!.title}</h3>
              </div>
              <a
                href={urlRs("articles", article()!.path.alias)}
                onClick={() => {
                  sendClick("btn-article", "articles");
                }}
                title={`Lire la suite de ${article()!.title}`}
                data-test="content-link"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconKeyboardArrowRight />
                </i>
                <span class="legend">Lire la suite</span>
              </a>
            </article>
          </Show>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
